import React from 'react';
import Layout from '../components/Layout';

const ContactSuccessPage = () => (
  <Layout>
    <div className="small-gap" />
    <div className="container">
      <h2 style={{ textAlign: 'center' }}>Thanks for submitting.</h2>
      <div className="small-gap" />
      <h3 style={{ textAlign: 'center' }}>We will be in touch shortly.</h3>

    </div>
    <div className="small-gap" />
  </Layout>
);

export default ContactSuccessPage;
